<template>
  <div class="container">
    <TitleHeader>Application Search</TitleHeader>
    <div class="card">
      <div class="card-body">
        <SearchApplications @reset="handleReset" @result="handleResult">
          <template #filter>
            <Filter @filterset="handleFilter" @reset="handleReset"/>
          </template>
        </SearchApplications>
        <ApplicationList
            :search-application="
            searchApplicationResult || getAssignedApplications
          "
            :show-status="true"
            @selected="handleSelect"
            @list-status-change="gatherApplicationData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import ApplicationList from "@/components/lists/ApplicationList";
import SearchApplications from "@/components/search/SearchApplications";
import Filter from "@/components/search/Filter";

export default {
  name: "Applications",
  components: {
    ApplicationList,
    SearchApplications,
    Filter
  },
  data: () => ({
    searchApplicationResult: null,
    params: null
  }),
  computed: {
    ...mapGetters(["getAssignedApplications"])
  },
  methods: {
    ...mapMutations(["setApplicationUuid"]),
    ...mapActions(["filterApplication"]),
    async gatherApplicationData() {
      await this.handleFilter(this.params);
    },
    handleReset() {
      this.searchApplicationResult = null;
    },
    handleResult(result) {
      this.searchApplicationResult = result;
    },
    handleSelect(uuid) {
      this.setApplicationUuid(uuid);
      this.$router.push({
        name: "Application Focus",
        params: {
          uuid: uuid
        }
      });
    },
    async handleFilter(params) {
      this.params = params;
      let response = await this.filterApplication({params}).catch(err =>
          console.log(err)
      );
      if (response.data.data.length > 0) {
        this.searchApplicationResult = response.data.data;
      } else {
        this.searchApplicationResult = [];
      }
    }
  }
};
</script>
