<template>
  <div class="card mb-4">
    <div class="card-body">
      <TextInput
        name="searchApplications"
        type="text"
        label="Search applications"
        class="pr-1 m-0"
        @updated="handleUpdate"
      />
      <div class="row">
        <div class="col-6">
          <slot name="filter"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "SearchApplications",
  props: {
    type: {
      default: null
    }
  },
  emits: ["result", "reset"],
  data: () => ({
    searchThrottle: null,
    waitTime: 1000 // milliseconds
  }),
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapMutations(["setMLTextSearchEntities", "clearMLTextSearchEntities"]),
    ...mapActions(["searchMLApplications", "searchApplications"]),
    async wait(ms) {
      return new Promise(resolve => {
        this.searchThrottle = setTimeout(resolve, ms);
      });
    },
    async handleUpdate(value) {
      clearTimeout(this.searchThrottle);
      if (!value) {
        this.$emit("reset");
        return;
      }
      await this.wait(this.waitTime);
      this.handleSearch(value);
    },
    async handleSearch(value) {
      let searchResponse = null;
      switch (this.type) {
        case "application":
          searchResponse = await this.searchApplications(value).catch(err =>
            console.log(err)
          );
          break;
        case "ml":
          searchResponse = await this.searchMLApplications(value).catch(err =>
            console.log(err)
          );
          break;
        default:
          searchResponse = await this.searchApplications(value).catch(err =>
            console.log(err)
          );
      }
      if (!searchResponse) return;
      this.$emit("result", searchResponse.data.data);
    }
  }
};
</script>
<style lang="scss" scoped></style>
