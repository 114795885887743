<template>
  <div class="filter">
    <p>
      <b>Filter:</b> <span class="link-text" @click="handleClear">clear</span>
    </p>
    <SelectInput
      name="filterStatus"
      label="Status"
      placeholder=""
      :options="getStatusAppValues"
      @selected="handleUpdated"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Filter",
  emits: ["filterset"],
  computed: {
    ...mapGetters(["getStatusAppValues"])
  },
  methods: {
    handleClear() {
      for (let [key, value] of Object.entries(this.$refs)) {
        if (/^radio-/.test(key)) {
          value.preSelected = null;
        }
      }
      this.$emit("reset", null);
    },
    handleUpdated(value) {
      this.$emit("filterset", { filterStatus: value });
    }
  }
};
</script>
<style lang="scss" scoped>
.filter {
  padding: $spacingBase 0 0;
  text-align: left;
}
</style>
